import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "./../components/Layout";
import SearchField from "./../components/SearchField";
import SearchResults from "./../components/SearchResults";
import { SearchMainWrapper } from "./../components/SearchField/elements";
// TODO: rich text
// TODO: dealing with spaces / multiple words

const getEntryFromId = (list, id) => (list.filter((x) => x.id === id).length ? list.filter((x) => x.id === id)[0] : []);

const SearchPage = () => {
  const [searchFor, setSearchFor] = React.useState("");
  const [eventsResults, setEventsResults] = React.useState([]);
  const [participantsResults, setParticipantsResults] = React.useState([]);
  const searchQuery = useStaticQuery(graphql`
    query {
      pageSeo: craftQafSearchQafSearchEntry {
        titleSeo
        descriptionSeo
      }
      events: allCraftQafEventsQafEventEntry(filter: { isStaging: { eq: false } }) {
        nodes {
          id
          slug
          title
          subtitle
          eventShortDescription
          eventEndDateTime
          eventStartDateTime
          qafEventLocation {
            slug
            title
            ... on Craft_participants_default_Entry {
              participantLocation {
                title
              }
              communityMemberOpeningHours
              communityMemberSlug
              communityMemberWebsite
              communityMemberEmailAddress
              communityMemberDescription
              communityMemberContactDetails {
                col1
                col2
              }
              qafParticipantType {
                ... on Craft_qafParticipantType_default_Entry {
                  id
                  title
                }
              }
            }
            ... on Craft_maplocation_default_Entry {
              locationId
              locationName
              title
              allowClick
            }
          }
          qafEventType {
            ... on Craft_qafEventType_default_Entry {
              id
              title
            }
          }
          headerImage: qafEventHeaderImage {
            ... on Craft_eventsAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(width: 400, quality: 100, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      participants: allCraftParticipantsDefaultEntry(filter: { isDraft: { eq: false } }) {
        nodes {
          id
          communityMemberSlug
          qafParticipantType {
            ... on Craft_qafParticipantType_default_Entry {
              id
              title
            }
          }
          communityMemberWebsite
          communityMemberEmailAddress
          subtitle
          communityMemberOpeningHours
          participantLocation {
            title
          }
          communityMemberDescription
          communityMemberContactDetails {
            method
            value
          }
          slug
          title
          headerImage: qafParticipantHeaderImage {
            ... on Craft_communityAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(width: 500, quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            ... on Craft_eventsAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(width: 500, quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const now = new Date();
  const events = searchQuery.events.nodes?.filter((item) => {
    const thisDate = new Date(item.eventEndDateTime || item.eventStartDateTime || null);
    return thisDate >= now;
  });
  const participants = searchQuery.participants.nodes;
  const { titleSeo, descriptionSeo } = searchQuery.pageSeo;

  useEffect(() => {
    for (let i = 0; i < participants?.length; i++) {
      const relatedE = events.filter((e) => {
        const checkedArray = e?.qafEventLocation?.length
          ? e?.qafEventLocation.filter((item) => item?.qafParticipantType && item?.qafParticipantType?.length)
          : [];

        return checkedArray.find((item) => item?.id === participants[i]?.id);
      });
      participants[i].relatedEvents = relatedE;
    }
  }, []);

  const eventsCorpus = events.map((x) => {
    return {
      id: x.id,
      corpus: `${x.title.toLowerCase()} | ${x.eventShortDescription ? x.eventShortDescription.toLowerCase() : ""} | ${
        x.subtitle ? x.subtitle.toLowerCase() : ""
      } | ${x?.qafEventType
        ?.map((type) => type?.title)
        .join(" ")
        .toLowerCase()} | ${x?.qafEventLocation
        .map((y) => y?.title)
        .join(" ")
        .toLowerCase()}`,
    };
  });

  const participantsCorpus = participants.map((x) => {
    return {
      id: x?.id,
      corpus: `${x?.title?.toLowerCase()} | ${x.participantLocation.map((item) => item?.title?.toLowerCase()).join(", ")} | ${
        x?.communityMemberDescription ? x?.communityMemberDescription?.toLowerCase() : ""
      }`,
    };
  });

  useEffect(() => {
    // if we are here, they are looking for something – query the corpuses
    if (searchFor.length > 2) {
      const eventsRawResults = eventsCorpus.filter((x) => x.corpus.indexOf(searchFor.toLowerCase()) > -1);
      const participantsRawResults = participantsCorpus.filter((x) => x.corpus.indexOf(searchFor.toLowerCase()) > -1);
      setEventsResults(eventsRawResults.map((x) => getEntryFromId(events, x.id)));
      setParticipantsResults(participantsRawResults.map((x) => getEntryFromId(participants, x.id)));
    } else {
      setEventsResults([]);
      setParticipantsResults([]);
    }
  }, [searchFor]);

  return (
    <Layout
      skipHeader
      headerBlack
      backgroundColor={"var(--bg-color)"}
      title={titleSeo ? titleSeo : "Alserkal: Search"}
      SEODescription={descriptionSeo}
    >
      <SearchMainWrapper>
        <SearchField
          searching={setSearchFor}
          totalResults={eventsResults.length + participantsResults.length}
          totalCategories={(eventsResults.length > 0 ? 1 : 0) + (participantsResults.length > 0 ? 1 : 0)}
        />
        <SearchResults eventsResults={eventsResults} participantsResults={participantsResults} />
      </SearchMainWrapper>
    </Layout>
  );
};

export default SearchPage;
